import './component.module.css'
import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['input', 'displayInput', 'list', 'item']

  focus () {
    this.listTarget.classList.remove('hidden')
  }

  focusout (event) {
    if (event && this.element.contains(event.target)) {
      event.stopPropagation()
      return
    }
    this.listTarget.classList.add('hidden')
  }

  select (event) {
    this.displayInputTarget.value = event.target.innerText
    this.inputTarget.value = event.target.dataset.value
    this.focusout()
  }

  filter (event) {
    this.inputTarget.value = event.target.value
    const value = event.target.value.toLowerCase()
    let hasVisibleItems = false

    this.itemTargets.forEach(item => {
      const itemText = item.innerText.toLowerCase()
      const isVisible = itemText.indexOf(value) !== -1
      item.classList.toggle('hidden', !isVisible)
      if (isVisible) {
        hasVisibleItems = true
      }
    })

    if (!hasVisibleItems) {
      this.showNoResultMessage()
    } else {
      this.hideNoResultMessage()
    }
  }

  showNoResultMessage () {
    if (!this.noResultElement) {
      const template = document.getElementById('no-result-template')
      this.noResultElement = template.content.cloneNode(true).firstElementChild
      this.listTarget.appendChild(this.noResultElement)
    }
    this.noResultElement.classList.remove('hidden')
  }

  hideNoResultMessage () {
    if (this.noResultElement) {
      this.noResultElement.classList.add('hidden')
    }
  }

  selectOther (event) {
    const selectedText = event.currentTarget.innerText
    const selectedValue = event.currentTarget.dataset.value
    this.displayInputTarget.value = selectedText
    this.inputTarget.value = selectedValue
    this.focusout()
  }
}
